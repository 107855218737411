<template>
  <div id="app">
    <h1>Kamino Mirrors</h1>
    <h2>mirrors.130825.xyz</h2>
    <div class="mirrors">
      <el-table :data="tableData" style="width: 100%" v-loading="dataLoading">
        <el-table-column prop="name" label="NAME" width="180">
        </el-table-column>
        <el-table-column label="SOURCE">
          <template slot-scope="scope">
            <a
              v-if="scope.row.source.search('http') !== -1"
              target="_blank"
              :href="scope.row.source"
              >{{ scope.row.source }}</a
            >
            <span v-else>{{ scope.row.source }}</span>
          </template>
        </el-table-column>
        <el-table-column label="MIRROR">
          <template slot-scope="scope">
            <a target="_blank" :href="scope.row.mirror">{{
              scope.row.mirror
            }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="TYPE" width="180">
        </el-table-column>
      </el-table>
    </div>
    <p>Powered by Cloudflare Workers</p>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
      dataLoading: true,
      tableData: [],
    };
  },
  mounted() {
    this.$axios.get("https://mirrors.130825.xyz/proxies").then((response) => {
      this.tableData = response.data;
      this.dataLoading = false;
    });
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  font-size: 44px;
  text-align: center;
}

h2 {
  font-size: 20px;
  text-align: center;
}

.mirrors {
  width: 80%;
  margin: 0 auto;
}

a {
  color: #606266;
  text-decoration: none;
}

a:hover {
  color: #606266;
  text-decoration: underline;
}
</style>
